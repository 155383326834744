import React from 'react';
import { useTrackEvent } from '@recordkeeping/common/build/analytics';
import { Stack } from '@recordkeeping/common/build/layout';

export default function AboutGbm() {
  const trackEvent = useTrackEvent('Link', { name: 'GBM' });

  function handleClickLink() {
    trackEvent();
  }

  return (
    <Stack space="lg">
      <p className="m-0 text-white">
        Grupo Bursátil Mexicano tiene más de 30 años de experiencia, el mejor
        equipo de analistas y asesores, y la tecnología más avanzada para que
        saques el máximo potencial de tu dinero.
      </p>
      <p className="m-0">
        <a
          onClick={handleClickLink}
          target="_blank"
          rel="noopener noreferrer"
          href="https://gbm.com/"
          className="link filter invert"
        >
          Quiero saber mas
        </a>
      </p>
    </Stack>
  );
}
