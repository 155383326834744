import shouldFetch from '@recordkeeping/common/build/shouldFetch';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getParticipantAccounts, GetAccountResponse } from '../api';
import { getAccountResponseToAccount } from '../mapper';

type UseParticipantAccountsOptions = {
  initDate: string | undefined;
  contractId: string | undefined;
};

export const KEY = 'participantAccounts';

export default function useParticipantAccounts(
  options: UseParticipantAccountsOptions,
) {
  const { data, isLoading, error } = useQuery<Array<GetAccountResponse>, Error>(
    [KEY, options],
    ({ signal }) =>
      getParticipantAccounts(
        {
          contractId: options.contractId!,
        },
        {
          initDate: options.initDate!,
        },
        signal,
      ),
    { enabled: shouldFetch(options) },
  );

  const accounts = useMemo(() => {
    return data == null ? undefined : data.map(getAccountResponseToAccount);
  }, [data]);

  return { accounts, isLoading, error };
}
