import React from 'react';
import { upperFirst } from '@recordkeeping/common/build/string';
import { useTranslation } from '@recordkeeping/common/build/internationalization';
import { Stack } from '@recordkeeping/common/build/layout';
import groupBy from 'lodash/groupBy';
import { Dictionary } from 'lodash';

import { Account } from '../types';
import SummaryItem from './SummaryItem';

type AccountsDetailProps = {
  accounts: Array<Account>;
};

export default function AccountGroupDetail({ accounts }: AccountsDetailProps) {
  const t = useTranslation();
  const groupedAccounts: Dictionary<Account[]> = getAccountingGroups();

  return (
    <>
      {Object.keys(groupedAccounts).map((key) => {
        const group = groupedAccounts[key];
        return (
          <article aria-label={upperFirst(`${key} detail`)}>
            <Stack space="lg">
              <SummaryItem
                aria-label={`${key} ${t('contributions balance')}`}
                label={upperFirst(t(key))}
                amount={group.reduce(
                  (total, account) => total + account.balance,
                  0,
                )}
                labelBold
                amountBold
              />

              <Stack key={key} space="md">
                {group.map((account) => (
                  <SummaryItem
                    aria-label={`${account.name} ${t('contributions balance')}`}
                    label={upperFirst(account.name)}
                    amount={account.balance}
                    nonUnderlined
                  />
                ))}
              </Stack>
            </Stack>
          </article>
        );
      })}
    </>
  );

  function getAccountingGroups() {
    accounts.sort((a, b) => {
      if (a.id < b.id) {
        return 1;
      }
      if (a.id > b.id) {
        return -1;
      }
      return 0;
    });
    return groupBy(accounts, (account) => {
      if (account.name.includes(t('share').toUpperCase())) {
        return 'contributions';
      }
      if (account.name.includes(t('yields').toUpperCase())) {
        return 'yields';
      }
      return 'loans';
    });
  }
}
