/* eslint-disable react/button-has-type */
import React, { HTMLProps, useState } from 'react';
import clsx from 'clsx';

type ButtonProps = HTMLProps<HTMLButtonElement> & {
  loading?: boolean;
  variant?: 'dark' | 'light';
  sizes?: 'big' | 'xsmall';
};

export default function Button({
  children,
  loading,
  sizes,
  variant = 'dark',
  ...props
}: ButtonProps) {
  const [isFocus, seIsFocus] = useState(false);

  return (
    <button
      {...props}
      // @ts-ignore
      type={props.type}
      onFocus={() => seIsFocus(true)}
      onBlur={() => seIsFocus(false)}
      className={clsx('button w-full', {
        'button--disabled': props.disabled,
        'button--loading': loading,
        'button--light': variant === 'light',
        'button--dark hover:text-gray-600': variant === 'dark',
        'button--big': sizes === 'big',
        'button--xsmall': sizes === 'xsmall',
      })}
    >
      {loading ? (
        <div className={clsx('button__loader', { 'filter invert': isFocus })}>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      ) : (
        children
      )}
    </button>
  );
}
