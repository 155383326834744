import React from 'react';
import { ReactFunctionComponentProps } from '@recordkeeping/common/build/types';

import useContractsQuery from 'queries/useContractsQuery';

import { ContractsProvider } from './ContractsProvider';

export default function ContractsQueryProvider({
  children,
}: ReactFunctionComponentProps) {
  const result = useContractsQuery();
  return <ContractsProvider value={result}>{children}</ContractsProvider>;
}
