/* eslint-disable camelcase */
import { Role } from '@recordkeeping/common/build/auth/AuthContext';
import api from 'client';

export type PostAuthenticationBody = {
  user: string;
  password: string;
};

export type PostAuthenticationReponse = {
  id: string;
  user_login: string;
  name: string;
  role_id: Role;
  token: string;
  refresh_token: string;
  client_id: string;
  participant_identifier: string;
};

export async function postAuthentication(
  body: PostAuthenticationBody,
): Promise<PostAuthenticationReponse> {
  const response = await api.post<PostAuthenticationReponse>(
    `/authentication`,
    {
      ...body,
    },
  );

  return response.data;
}

export async function postRefreshToken(
  refresh_token: string,
): Promise<PostAuthenticationReponse> {
  const response = await api.post<PostAuthenticationReponse>(
    `/authentication/refresh-token`,
    {
      refresh_token,
    },
  );

  return response.data;
}
