import React from 'react';
import { Inline } from '@recordkeeping/common/build/layout';
import useMediaQuery from '@recordkeeping/common/build/useMediaQuery';
import clsx from 'clsx';

import GBMLogo from './GBMLogo.svg';

type LogoProps = {
  invert?: boolean;
  small?: boolean;
};

export default function Logo({ invert, small: forceSmall }: LogoProps) {
  const isSmall = !useMediaQuery('sm');
  const small = forceSmall != null ? forceSmall : isSmall;

  return (
    <div className={clsx('filter', { invert })}>
      <Inline space="md" align="center">
        <img
          style={{ height: '21px' }}
          alt="GBM | Grupo Bursátil Mexicano"
          src={GBMLogo}
        />
        <span style={{ fontSize: 29 }} className="uppercase">
          {small ? 'RK' : 'Record Keeping'}
        </span>
      </Inline>
    </div>
  );
}
