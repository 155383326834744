/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import {
  Cover,
  Stack,
  Padding,
  Hidden,
  ContentBlock,
  Column,
  Columns,
} from '@recordkeeping/common/build/layout';

import Logo from '../components/Logo';
import ImageCover from './Cover';
import LoginForm from './LoginForm';

export default function Login() {
  return (
    <Columns>
      <Column width={[12, 10, 7, 5]}>
        <ContentBlock width={425}>
          <Padding padding={['lg', 'xl']} paddingTop="xl">
            <Stack space="xl">
              <Logo />
              <Cover height={['75vh', '80vh']}>
                <LoginForm />
              </Cover>
            </Stack>
          </Padding>
        </ContentBlock>
      </Column>
      <Hidden below="md">
        <Column width={[0, 2, 5, 7]}>
          <ImageCover />
        </Column>
      </Hidden>
    </Columns>
  );
}
