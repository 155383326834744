import memoize from '@recordkeeping/common/build/memoize';
import faker from 'faker';
import { GetLoanRulesResponse } from 'loans/api';

import createId from '../createId';

type CreateLoanRulesOption = {
  index: number;
  contractId: string;
};

export const createLoanRules = memoize(
  ({ index, contractId }: CreateLoanRulesOption): GetLoanRulesResponse => {
    const interestRate = faker.datatype.number({ min: 1, max: 100 });
    const baseLoanAmount = 10000;

    return {
      id: createId(),
      payment_frequency: 'eachFourteenDays',
      number_payments: [12, 24, 36, 48],
      name: faker.commerce.productName(),
      min_amount: baseLoanAmount * index,
      max_active_loans: 2,
      loan_balance_percent: 75,
      interest_rate: interestRate,
      contract_id: contractId,
      client_id: createId(),
      max_amount:
        index === 3 ? 999_999_999_999_999 : baseLoanAmount * (index + 1),
    };
  },
);
