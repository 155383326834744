import React, { forwardRef } from 'react';
import { useSprings, animated, SpringValue } from '@react-spring/web';
import useResponsiveProp from '@recordkeeping/common/build/useResponsiveProp';

import { FunctionComponentProps } from 'types';
import useReveal from 'landing/useReveal';

import EmpleadosJPG from './images/fondo-ahorro/empleados.jpg';
import InteresesJPG from './images/fondo-ahorro/intereses.jpg';
import EmpresaJPG from './images/fondo-ahorro/empresa.png';
import { useProductContext } from './Product';

// preserve current order
const images = [
  { src: EmpleadosJPG, alt: 'Empleados' },
  { src: InteresesJPG, alt: 'Intereses' },
  { src: EmpresaJPG, alt: 'Empresa' },
];

export default function FondoDeAhorroChart() {
  const { ref } = useProductContext();
  const isRevealed = useReveal(ref);
  const springs = useFadeInRightSprings(isRevealed);
  const height = useResponsiveProp(['17rem', '39rem']);

  return (
    <div className="relative w-full overflow-hidden " style={{ height }}>
      {springs.map((springValue, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <FadeInRightBlock key={index} index={index} springValue={springValue}>
          <Frame src={images[index].src} alt={images[index].alt} />
        </FadeInRightBlock>
      ))}
    </div>
  );
}

type FrameProps = {
  src: string;
  alt: string;
};

const Frame = forwardRef<HTMLDivElement, FrameProps>(
  // eslint-disable-next-line react/prop-types
  ({ src, alt }, ref) => {
    return (
      <div ref={ref} className="h-40 md:h-80 w-44 md:w-80">
        <img alt={alt} src={src} className="w-full h-full object-cover" />
      </div>
    );
  },
);

type FadeInRightSpringValue = {
  transform: SpringValue<number>;
  opacity: SpringValue<number>;
};

function useFadeInRightSprings(enabled: boolean = true) {
  const springs = useSprings(
    images.length,
    images.map((_, index) => ({
      pause: !enabled,
      delay: 75 * (index + 1),
      from: { transform: -50, opacity: 0 },
      to: { transform: 0, opacity: 1 },
    })),
  );

  return springs;
}

export type FadeInRightBlockProps = FunctionComponentProps<{
  springValue: FadeInRightSpringValue;
  index: number;
}>;

export function FadeInRightBlock({
  children,
  springValue,
  index,
}: FadeInRightBlockProps) {
  const left = useResponsiveProp([
    `calc(${index * 33}% - 30px)`,
    `calc(${index * 33}% - 55px)`,
  ]);

  return (
    <animated.div
      className="absolute"
      style={{
        left,
        top: index !== 1 ? 'initial' : '0%',
        bottom: index === 1 ? 'initial' : '0%',
        opacity: springValue.opacity.to((x) => x),
        transform: springValue.transform.to((x) => `translate(${x}px, 0px)`),
      }}
    >
      {children}
    </animated.div>
  );
}
