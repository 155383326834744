import memoize from '@recordkeeping/common/build/memoize';
import faker from 'faker';

import { GetAccountResponse } from 'resume';

import createId from '../createId';

export type CreateAccountResponseOptions = {
  participantId: string;
  clientId?: string;
  contractId?: string;
  index: number;
};

export const createAccountResponse = memoize((): GetAccountResponse => {
  return {
    id: createId(),
    account_master_type_id: createId(),
    yield: parseFloat(faker.finance.amount(0, 1_000_000, 2)),
    name: faker.commerce.productName(),
    interest: parseFloat(faker.finance.amount(0, 1_000_000, 2)),
    initial_balance: parseFloat(faker.finance.amount(0, 1_000_000, 2)),
    debit: parseFloat(faker.finance.amount(0, 1_000_000, 2)),
    credit: parseFloat(faker.finance.amount(0, 1_000_000, 2)),
    balance: parseFloat(faker.finance.amount(0, 1_000_000, 2)),
  };
});
