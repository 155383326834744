import React, { forwardRef, useEffect, useRef } from 'react';
import { useHoverDirty, useMedia } from 'react-use';
import { useSpring, animated, to } from '@react-spring/web';
import clsx from 'clsx';
import useResponsiveProp from '@recordkeeping/common/build/useResponsiveProp';

import { FunctionComponentProps } from 'types';
import useReveal from 'landing/useReveal';

import { useProductContext } from './Product';

// const baseStyle = { y: 'calc(50% - 160px)', x: '48%' };
const x = '48%';

export default function FondoDePensionesChart() {
  return (
    <div className="relative w-full h-64 md:h-96 overflow-hidden md:overflow-visible">
      <CompanyContribution />
      <EmployeeContribution />
    </div>
  );
}

type CircleProps = FunctionComponentProps<{
  className: string;
}>;

const Circle = forwardRef<HTMLDivElement, CircleProps>(
  // eslint-disable-next-line react/prop-types
  ({ className, children }, ref) => {
    const classNames = clsx([
      'rounded-full h-40 md:h-80 w-40 md:w-80',
      className,
    ]);

    return (
      <div ref={ref} className={classNames}>
        <div className="w-full h-full flex justify-center items-center text-h5 text-center">
          {children}
        </div>
      </div>
    );
  },
);

function EmployeeContribution() {
  const ref = useRef(null);
  const zoom = useHoverZoomAnimation(ref);

  return (
    <ZoomAndScale zoom={zoom} style={{ left: x }}>
      <Circle ref={ref} className="bg-secondary-blue text-white bg-opacity-90">
        Aportación
        <br /> Empleado
      </Circle>
    </ZoomAndScale>
  );
}

function CompanyContribution() {
  const ref = useRef(null);
  const zoom = useHoverZoomAnimation(ref);

  return (
    <ZoomAndScale zoom={zoom} style={{ right: x }}>
      <Circle ref={ref} className="bg-secondary-human">
        Aportación
        <br /> Empresa
      </Circle>
    </ZoomAndScale>
  );
}

function ZoomAndScale({ zoom, children, style }: any) {
  const { ref } = useProductContext();
  const isRevealed = useReveal(ref);
  const scale = useScaleAnimation(isRevealed);
  const circleStyle = useCircleSize();

  return (
    <animated.div
      className="absolute"
      style={{
        ...style,
        top: circleStyle,
        cursor: 'grab',
        scale: to([scale, zoom], (s, z) => s + z),
      }}
    >
      {children}
    </animated.div>
  );
}

function useScaleAnimation(enabled: boolean = true) {
  const prefersReducedMotion = useMedia('(prefers-reduced-motion)');
  const { scale } = useSpring({
    from: { scale: 0 },
    to: { scale: 1 },
    pause: !enabled,
    immediate: prefersReducedMotion,
    config: {
      easing: (t) =>
        t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
    },
  });

  return scale;
}

export function useHoverZoomAnimation(ref: any) {
  const prefersReducedMotion = useMedia('(prefers-reduced-motion)');
  const isHovering = useHoverDirty(ref);
  const [{ zoom }, api] = useSpring(() => ({
    zoom: 0,
    immediate: prefersReducedMotion,
    config: {
      easing: (t) =>
        t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
    },
  }));

  useEffect(() => {
    if (isHovering) {
      api.start({ zoom: 0.2 });
    } else {
      api.start({ zoom: 0 });
    }
  }, [api, isHovering]);

  return zoom;
}

function useCircleSize() {
  return useResponsiveProp(['calc(50% - 80px)', 'calc(50% - 160px)']);
}
