import times from 'lodash/times';
import { rest } from '@recordkeeping/common/build/msw/server';

import { createParticipantResponse } from './factories';

const MAX_PAGE = 10;
const DEFAULT_INITIAL_PAGE = '1';
const DEFAULT_PAGE_SIZE = '20';

export default [
  rest.get(
    'https://api.recordkeeping.dev-gbm.com/v2/participants',
    async (req, res, ctx) => {
      const page = parseInt(
        req.url.searchParams.get('page') ?? DEFAULT_INITIAL_PAGE,
        10,
      );
      const pageSize = parseInt(
        req.url.searchParams.get('pageSize') ?? DEFAULT_PAGE_SIZE,
        10,
      );

      if (page > 10) {
        return res(
          ctx.json({
            items: [],
            pagination_metadata: {
              page,
              next: null,
              page_count: MAX_PAGE,
              page_size: pageSize,
              previous: `?page=${page - 1}&page_size=${pageSize}`,
              total_items: MAX_PAGE * pageSize,
            },
          }),
        );
      }

      const participants = times(pageSize, (index) =>
        createParticipantResponse({
          contractId: req.url.searchParams.get('contractId'),
          name: req.url.searchParams.get('search'),
          index,
          page,
        }),
      );

      return res(
        ctx.json({
          items: participants,
          pagination_metadata: {
            page,
            next: `?page=${page + 1}&page_size=${pageSize}`,
            page_count: MAX_PAGE,
            page_size: pageSize,
            previous:
              page === 1 ? null : `?page=${page - 1}&page_size=${pageSize}`,
            total_items: MAX_PAGE * pageSize,
          },
        }),
      );
    },
  ),
];
