/* eslint-disable no-redeclare */
import automapper from '@recordkeeping/common/build/automapper';

import { Account } from './types';
import { GetAccountResponse } from './api';

export function getAccountResponseToAccount(
  response: GetAccountResponse,
): Account {
  return automapper<GetAccountResponse, Account>(response, {
    id: true,
    accountMasterTypeId: (src) => src.account_master_type_id,
    yield: true,
    name: true,
    interest: true,
    initialBalance: (src) => src.initial_balance,
    debit: true,
    credit: true,
    balance: true,
  });
}
