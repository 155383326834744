import { rest } from '@recordkeeping/common/build/msw/server';
import { decode } from '@recordkeeping/common/build/jwt';

export default [
  rest.get(
    'https://api.recordkeeping.dev-gbm.com/v2/participants/reports',
    async (req, res, ctx) => {
      const params = ['Final_Date', 'Initial_Date'];

      const authorization = req.headers.get('Authorization');
      if (authorization != null) {
        const user = decode(authorization);
        if (user.role_id === 'CLIENT_ADMIN') params.push('IdentifiersFilters');
      }

      return res(
        ctx.json({
          items: [
            {
              params,
              id: '3',
              name: 'Estado cuenta individual',
              path: '/ReportServer/Pages/ReportViewer.aspx?/RecordKeeping/Estado_Cuenta_Individual_Flex&rs:Command=Render&rc:Zoom=150',
            },
            {
              id: '7',
              name: 'Saldos Historicos',
              path: '/ReportServer/Pages/ReportViewer.aspx?/RecordKeeping/Estado_Cuenta_Individual_Flex&rs:Command=Render&rc:Zoom=150',
              params: [
                'Final_Date',
                'Initial_Date',
                'IdentifiersFilters',
                'GroupingFilters',
              ],
            },
            {
              id: '8',
              name: 'Saldos Netos',
              path: '/ReportServer/Pages/ReportViewer.aspx?/RecordKeeping/Estado_Cuenta_Individual_Flex&rs:Command=Render&rc:Zoom=150',
              params: ['Final_Date', 'IdentifiersFilters', 'GroupingFilters'],
            },
            {
              id: '2',
              name: 'Estado de Cuenta Integral',
              path: '/ReportServer/Pages/ReportViewer.aspx?/RecordKeeping/Estado_Cuenta_Individual_Flex&rs:Command=Render&rc:Zoom=150',
              params: [
                'Final_Date',
                'Initial_Date',
                'IdentifiersFilters',
                'GroupingFilters',
              ],
            },
          ],
        }),
      );
    },
  ),
  rest.get(
    'https://api.recordkeeping.dev-gbm.com/v2/reports',
    async (req, res, ctx) => {
      // await wait(1500);
      // const originalResponse = await ctx.fetch(req);
      // const originalResponseData = await originalResponse.json();
      // return res(ctx.json(originalResponseData));
      return res(
        ctx.json({
          name: 'Estado cuenta individual',
          content: '',
        }),
      );
    },
  ),
];
