import memoize from '@recordkeeping/common/build/memoize';
import faker from 'faker';

import createId from '../createId';

export const createParticipantResponse = memoize(
  ({ name = '', contractId = faker.random.word() }) => {
    return {
      id: createId(),
      name: faker.name.findName(name),
      contracts: [contractId, faker.random.word()],
    };
  },
);
