import React from 'react';
import { FunctionComponentProps } from 'types';

export default function HeaderBackToTopButton({
  children,
}: FunctionComponentProps) {
  function scrollToTop(e) {
    const key = e.keyCode || e.which;
    if (key != null && key !== 13) {
      return;
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <div
      className="cursor-pointer"
      role="button"
      tabIndex={0}
      onKeyPress={scrollToTop}
      onClick={scrollToTop}
    >
      {children}
    </div>
  );
}
