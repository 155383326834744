var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback } from 'react';
import { useAnalytics } from './AnalyticsContext';
export default function useTrackEvent(event, data) {
    var analytics = useAnalytics();
    return useCallback(function (eventData) {
        analytics.track(event, __assign(__assign({}, data), eventData));
    }, [analytics, data, event]);
}
