import React from 'react';

import {
  ContentBlock,
  Columns,
  Column,
  Padding,
} from '@recordkeeping/common/build/layout';

import GBMLogo from 'components/GBMLogo.svg';

import ContactBlock from './ContactBlock';
import AboutGbm from './AboutGbm';

export default function Footer() {
  return (
    <footer className="bg-black">
      <ContentBlock width={['md', 'md', 'md', 'xl']}>
        <Padding padding="lg" paddingTop="xxl" paddingBottom="xxl">
          <Columns columns={[1, 1, 1, 5]} space={['xxl', 'xxl', 'xxl', 'none']}>
            <Column width={1}>
              <img
                className="w-auto h-12 filter invert"
                alt="GBM | Grupo Bursátil Mexicano"
                src={GBMLogo}
              />
            </Column>
            <Column width={[1, 2]}>
              <ContactBlock />
            </Column>
            <Column width={[1, 2]}>
              <AboutGbm />
            </Column>
          </Columns>
        </Padding>
      </ContentBlock>
    </footer>
  );
}
