import createContextSlice from '@recordkeeping/common/build/createContextSlice';

import { Contract } from 'contracts';
import { Account } from '../types';

interface ContractResumeContextValue {
  contract: Contract | undefined;
  accounts: Array<Account> | undefined;
  isLoading: boolean;
  error: Error | null;
}

const [
  ContractResumeProvider,
  useContractResumeContext,
  ContractResumeContext,
] = createContextSlice<ContractResumeContextValue>(undefined, 'contractResume');

export {
  ContractResumeProvider,
  useContractResumeContext,
  ContractResumeContext,
};
