import { session } from '@recordkeeping/common/build/auth';
import { useEffectAfterMount } from '@recordkeeping/common/build/hooks';

import useLoginMutation from './useLoginMutation';

export default function useLogin() {
  const [loginMutation, { error, isLoading, data }] = useLoginMutation();

  useEffectAfterMount(() => {
    if (data != null && error == null) {
      session.storeSession({
        token: data.token!,
        refreshToken: data.refreshToken!,
        identity: data.identity!,
      });
    }
  }, [data, error]);

  return [loginMutation, { error, isLoading }] as const;
}
