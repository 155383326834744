var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { Stack as StackBase } from '@bedrock-layout/stack';
import useResponsiveProp from '../useResponsiveProp';
var StackStyle = styled(StackBase)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: inherit;\n"], ["\n  width: inherit;\n"])));
export default function Stack(_a) {
    var children = _a.children, _b = _a.space, space = _b === void 0 ? 'none' : _b;
    var spaceProp = useResponsiveProp(space);
    return _jsx(StackStyle, __assign({ gutter: spaceProp }, { children: children }), void 0);
}
var templateObject_1;
