import React from 'react';
import Link from '@recordkeeping/common/build/navigation/Link';
import { useTrackEvent } from '@recordkeeping/common/build/analytics';

import { FunctionComponentProps } from 'types';

export default function HeaderGoToPortalLink({
  children,
}: FunctionComponentProps) {
  const trackEvent = useTrackEvent('Ingresar');

  function handleClickLink() {
    trackEvent();
  }

  return (
    <Link onClick={handleClickLink} href="/auth">
      <span className="font-base font-bold text-black no-underline">
        {children}
      </span>
    </Link>
  );
}
