/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useAuth, useIdentity } from '@recordkeeping/common/build/auth';
import { Stack } from '@recordkeeping/common/build/layout';
import { useHistory } from '@recordkeeping/common/build/navigation';
import { useFeatureFlags } from '@recordkeeping/common/build/featureFlags/FeatureFlagContext';
import { useTranslation } from '@recordkeeping/common/build/internationalization';
import { upperFirst } from '@recordkeeping/common/build/string';

import * as summary from 'resume';
import Button from 'components/Button';

import useLoginForm from './useLoginForm';

export default function LoginForm() {
  const t = useTranslation();
  const [error, setError] = React.useState(false);
  const auth = useAuth();
  const history = useHistory();
  const { identity, error: identityError } = useIdentity();
  const featureFlags = useFeatureFlags();

  const form = useLoginForm(async (values) => {
    if (!featureFlags.PORTAL_V2) {
      return setError(true);
    }

    try {
      await auth.login({ user: values.user, password: values.password });
      return history.push(summary.RESUME_PATH);
    } catch {
      setError(true);
    }
  });

  // validate if user already have a session
  useEffect(() => {
    if (identityError == null && identity?.id != null) {
      history.push(summary.RESUME_PATH);
    }
  }, [identity, identityError, history]);

  // sync feature flag context on form change
  useEffect(() => {
    if (form.values.user != null) {
      featureFlags.setContext({ email: form.values.user });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlags.setContext, form.values.user]);

  return (
    <form onSubmit={form.handleSubmit}>
      <p className="font-bold text-2xl">{upperFirst(t('log in'))}</p>
      <Stack space="xl">
        <div className="input w-full">
          <label className="input__label" htmlFor="email">
            {upperFirst(t('user'))}
          </label>
          <div className="input__group">
            <input
              autoComplete="off"
              type="text"
              name="user"
              className="input__input"
              maxLength={256}
              minLength={6}
              id="user"
              required
              value={form.values.user}
              onChange={form.handleChange}
            />
          </div>
        </div>
        <div className="input w-full">
          <label className="input__label" htmlFor="password">
            {upperFirst(t('password'))}
          </label>
          <div className="input__group">
            <input
              type="password"
              name="password"
              className="input__input"
              maxLength={32}
              minLength={8}
              id="password"
              autoCapitalize="off"
              required
              value={form.values.password}
              onChange={form.handleChange}
            />
          </div>
        </div>

        {error ? (
          <p className="text-red-500">
            {upperFirst(
              t('the username and/or password you entered are incorrect.'),
            )}
          </p>
        ) : null}

        <Button
          type="submit"
          variant="dark"
          disabled={!form.dirty || !form.isValid}
          loading={form.isSubmitting}
          sizes="big"
        >
          {upperFirst(t('log in'))}
        </Button>
      </Stack>
    </form>
  );
}
