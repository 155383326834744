import React from 'react';
import { Box, Padding } from '@recordkeeping/common/build/layout';

import PageGutter from 'components/PageGutter';

import ContractResumeProvider from './ContractResumeProvider';
import AccountsResume from './AccountsResume';
import ContractResumeHeader from './ContractResumeHeader';

export default function ContractResume() {
  return (
    <PageGutter>
      <ContractResumeProvider>
        <Padding padding="none" paddingBottom="xxl">
          <Box direction="column" width="full">
            <ContractResumeHeader />
            <AccountsResume />
          </Box>
        </Padding>
      </ContractResumeProvider>
    </PageGutter>
  );
}
