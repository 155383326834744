import times from 'lodash/times';
import { rest } from '@recordkeeping/common/build/msw/server';

import { createAccountResponse } from './factories';

export default [
  rest.get(
    'https://api.recordkeeping.dev-gbm.com/v2/participants/:participantId/accounts',
    (req, res, ctx) => {
      const contracts = times(2, () => createAccountResponse());
      return res(ctx.json(contracts));
    },
  ),
];
