import { QueryResult } from '@recordkeeping/common/build/types';
import { createContext } from '@snacks/common';

export type Contract = {
  id: string;
  type: string;
  name: string;
  clientId: string;
  balance: number;
  createdAt: Date;
};

export const [ContractsProvider, useContractsValue] =
  createContext<QueryResult<ReadonlyArray<Contract>>>('contracts');
