import React from 'react';
import styles from './Cover.module.css';

export default function Cover() {
  return (
    <div
      className={`${styles.cover} ${styles['cover-background']} bg-cover cursor-default overflow-hidden`}
    />
  );
}
