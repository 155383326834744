var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Flex as FlexBase } from 'reflexbox/styled-components';
import useResponsiveProp from '../useResponsiveProp';
export default function Box(_a) {
    var _b = _a.component, component = _b === void 0 ? 'span' : _b, _c = _a.align, align = _c === void 0 ? 'start' : _c, children = _a.children, _d = _a.direction, direction = _d === void 0 ? 'row' : _d, _e = _a.justify, justify = _e === void 0 ? 'start' : _e, flex = _a.flex, _f = _a.width, width = _f === void 0 ? 'auto' : _f, _g = _a.height, height = _g === void 0 ? 'auto' : _g;
    var alignProp = useResponsiveProp(align);
    var directionProp = useResponsiveProp(direction);
    var heightProp = useResponsiveProp(mapSizingProp(height));
    var justifyProp = useResponsiveProp(justify);
    var widthProp = useResponsiveProp(mapSizingProp(width));
    return (_jsx(FlexBase, __assign({ component: component, alignItems: mapAlignProp(alignProp), flex: flex, flexDirection: directionProp, height: heightProp, justifyContent: mapJustifyProp(justifyProp), width: widthProp }, { children: children }), void 0));
}
function mapSizingProp(size) {
    return size === 'full' ? '100%' : 'auto';
}
var justifyMap = {
    between: 'space-between',
    around: 'space-around',
    end: 'flex-end',
    start: 'flex-start',
    center: 'center',
};
function mapJustifyProp(justifyProp) {
    return justifyProp != null ? justifyMap[justifyProp] : justifyProp;
}
var alignMap = {
    end: 'flex-end',
    start: 'flex-start',
    center: 'center',
    base: 'baseline',
};
function mapAlignProp(alignProp) {
    return alignProp != null ? alignMap[alignProp] : alignProp;
}
