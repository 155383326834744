var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import './i18nConfig';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InternationalizationProvider, } from './InternationalizationContext';
export default function I18nextProvider(_a) {
    var children = _a.children;
    var t = useTranslation().t;
    var value = useMemo(function () {
        return {
            translate: function (phrase, _a) {
                if (_a === void 0) { _a = {}; }
                var dictionary = _a.dictionary, data = __rest(_a, ["dictionary"]);
                if (dictionary != null) {
                    // eslint-disable-next-line no-param-reassign
                    data.ns = dictionary;
                }
                return t(phrase, data);
            },
        };
    }, [t]);
    return (_jsx(InternationalizationProvider, __assign({ value: value }, { children: children }), void 0));
}
