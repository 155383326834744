import React from 'react';
import { FunctionComponentProps } from 'types';

import styles from './Hero.module.css';

export default function Hero({ children }: FunctionComponentProps) {
  return (
    <div
      className={`${styles.hero} ${styles['hero-background']} bg-cover cursor-default overflow-hidden`}
    >
      {children}
    </div>
  );
}
