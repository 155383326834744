import useMediaQueryBase from '@bedrock-layout/use-media-query';
export default function useMediaQuery(screen) {
    return useMediaQueryBase("(min-width:" + sizeMap[screen] + "px)");
}
export function getScreenSize(screen) {
    return sizeMap[screen];
}
var sizeMap = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536,
};
