import React, { ReactNode } from 'react';
import { Padding, Columns, Column } from '@recordkeeping/common/build/layout';
import { ReactFunctionComponentProps } from '@recordkeeping/common/build/types';
import { upperFirst } from '@recordkeeping/common/build/string';
import { useTranslation } from '@recordkeeping/common/build/internationalization';
import clsx from 'clsx';

type ContractItemProps = ReactFunctionComponentProps<{
  left: ReactNode;
  right: ReactNode;
}>;

export default function ContractItem({
  left,
  right,
  ...props
}: ContractItemProps) {
  const t = useTranslation();
  const textClassName = clsx('text-body2');

  return (
    <Padding padding="md" paddingTop="lg" paddingBottom="lg" {...props}>
      <Columns columns={8}>
        <Column width={[4, 6]}>
          <p className={textClassName}>{left}</p>
        </Column>
        <Column width={[4, 2]}>
          <p
            aria-label={upperFirst(t(`current balance`))}
            className={clsx(textClassName, 'text-right')}
          >
            {right}
          </p>
        </Column>
      </Columns>
    </Padding>
  );
}

export function ContractItemWrapper({
  children,
  ...props
}: ReactFunctionComponentProps) {
  return (
    <div
      className="transition-all border-0 border-b border-gray-300 border-solid hover:border-transparent"
      {...props}
    >
      <div className="transition-all bg-black bg-opacity-0 hover:bg-opacity-5 rounded cursor-pointer">
        {children}
      </div>
    </div>
  );
}
