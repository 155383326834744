import { useRef, useCallback, useState } from 'react';

export default function useStateRef() {
  const ref = useRef(null);
  const [hasRefChange, setHasRefChange] = useState<boolean>();

  const onRefChange = useCallback((node) => {
    if (node !== null) {
      ref.current = node;
      setHasRefChange(true);
    }
  }, []);

  return [ref, hasRefChange, onRefChange] as const;
}
