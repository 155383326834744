import React, { useMemo, useCallback } from 'react';
import { ReactFunctionComponentProps } from '@recordkeeping/common/build/types';
import {
  AuthProvider,
  Permission,
  session,
} from '@recordkeeping/common/build/auth';

import useLogin from './useLogin';
import useRefreshSession from './useRefreshSession';

export default function BasicAuthProvider({
  children,
}: ReactFunctionComponentProps) {
  const [login, { isLoading: isLoggingIn, error: hasLoginError }] = useLogin();
  const [refreshSession] = useRefreshSession();

  const checkAuth = useCallback(async () => {
    return session.isValidSession();
  }, []);

  const logout = useCallback(async () => {
    return session.removeSession();
  }, []);

  const getIdentity = useCallback(async () => {
    return session.getIdentity();
  }, []);

  const value = useMemo(() => {
    return {
      login,
      isLoggingIn,
      hasLoginError: hasLoginError != null,
      checkAuth,
      logout,
      getIdentity,
      refreshSession,
      // TODO: implement methods
      getPermissions: () => Promise.resolve(new Set<Permission>()),
    };
  }, [
    login,
    isLoggingIn,
    hasLoginError,
    checkAuth,
    logout,
    getIdentity,
    refreshSession,
  ]);

  return <AuthProvider value={value}>{children}</AuthProvider>;
}
