import { snakeCase } from './string';
export function mapKeys(json, strategy) {
    var result = {};
    // eslint-disable-next-line guard-for-in
    for (var key in json) {
        result[strategy(key)] = json[key];
    }
    return result;
}
export function snakeCaseStrategy(key) {
    return snakeCase(key);
}
