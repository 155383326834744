import React from 'react';
import { Padding } from '@recordkeeping/common/build/layout';
import { ReactFunctionComponentProps } from '@recordkeeping/common/build/types';

export default function PageGutter({ children }: ReactFunctionComponentProps) {
  return (
    <Padding padding={['lg', 'xxl']} paddingTop="xxl">
      {children}
    </Padding>
  );
}
