import axios from 'axios';
import getConfig from '@recordkeeping/common/build/config/config';
import { session } from '@recordkeeping/common/build/auth';

import { postRefreshToken } from 'login/api';

const config = getConfig();

const client = axios.create({
  baseURL: `https://api.recordkeeping.${config.API_DOMAIN}.com/v2`,
  headers: {
    'x-api-key': JSON.parse(config.SERVERLESS_API_KEY),
  },
});

client.interceptors.request.use(async (requestConfig) => {
  let Authorization;
  try {
    Authorization = session.getToken();
  } catch {
    // do nothing
  }

  if (Authorization != null) {
    // eslint-disable-next-line no-param-reassign
    requestConfig.headers.Authorization = Authorization;
  }

  return requestConfig;
});

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { newrelic } = window as any;
    if (newrelic != null) {
      newrelic?.noticeError(error);
    }

    if (
      error.response?.status === 401 &&
      // prevent refetching on the login request
      !error.response?.config.url.includes('/authentication')
    ) {
      try {
        await handleRefreshToken();
      } catch (err) {
        window.location.replace('/auth');
        session.removeSession();
      }
    }

    return Promise.reject(error);
  },
);

const handleRefreshToken = async () => {
  const refreshToken = session.getRefreshToken();
  if (refreshToken == null) throw new Error('invalid session');

  const result = await postRefreshToken(refreshToken);
  session.storeSession({
    token: result.token,
    refreshToken: result.refresh_token,
    identity: {
      id: result.id,
      username: result.user_login,
      email: result.user_login,
      name: result.name,
      clientId: result.client_id,
      roleId: result.role_id,
    },
  });
};

export function createCancelTokenFromSignal(signal?: AbortSignal) {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  // Cancel the request if React Query signals to abort
  signal?.addEventListener('abort', () => source.cancel());
  return source.token;
}

export default client;
