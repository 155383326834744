import React from 'react';
import { Stack } from '@recordkeeping/common/build/layout';

import Product from './Product';
import ProductContent from './ProductContent';
import CajaDeAhorroChart from './CajaDeAhorroChart';
import { DetailList } from './DetailList';
import FondoDeAhorroChart from './FondoDeAhorroChart';
import FondoDePensionesChart from './FondoDePensionesChart';

export default function ProductList() {
  return (
    <Stack space="xl">
      {productData.map((productInfo) => {
        return (
          <Product
            key={productInfo.id}
            graph={productInfo.graph}
            details={<DetailList details={productInfo.details} />}
            content={
              <ProductContent
                alt={productInfo.alt}
                headline={productInfo.headline}
                name={productInfo.name}
              />
            }
          />
        );
      })}
    </Stack>
  );
}

const productData = [
  {
    id: 'caja-de-ahorro',
    name: 'Caja de ahorro (LISR Art. 54)',
    headline:
      'Fondo integrado por las aportaciones de empleados en la nomina de la empresa',
    alt: 'Fomenta cultura del ahorro y Formaliza la administración de cajas internas',
    graph: <CajaDeAhorroChart />,
    details: [
      'Caja de ahorro apegada a los artículos de ley LISR. Art. 54',
      'Se integran únicamente de las aportaciones voluntarias de los empleados',
      'La empresa participa solamente en la administración y vigilancia del buen funcionamiento de los préstamos solicitados y del ahorro de los empleados',
      'Pueden ser cíclicas o permanentes',
      <b>Beneficios para las empresas</b>,
      [
        'Mejora clima organizacional',
        'Formaliza la administración de cajas internas (tandas)',
        'Robustece paquete total de compensaciones',
      ],
      <b>Beneficios para los Participantes</b>,
      [
        'Aprobación de líneas de crédito privadas sin consulta en Buró de Crédito',
        'Prestamos a tasas de interés más bajas que el mercado',
        'Descuentos vía nómina',
        'Sin pago de ISR de intereses ganados por inversión',
        'Sin pago de IVA por los intereses del préstamo',
      ],
    ],
  },
  {
    id: 'fondo-de-ahorro',
    name: 'Fondo de ahorro (LISR Art. 27; RLISR. Art. 49)',
    headline:
      'Fondo integrado por los empleados y la empresa, como beneficio para sus empleados',
    alt: 'Deducible hasta el 53% de las aportaciones',
    graph: <FondoDeAhorroChart />,
    details: [
      'Fondo de ahorro apegado a los artículos de ley LISR. Art. 27; RLISR. Art. 49',
      'Es una prestación de la empresa hacia sus empleados',
      'Se integra de la participación por igual por parte del empleado y la compañía, aportando la misma cantidad de las aportaciones',
      'Son cíclicas',
      <b>Beneficios para las empresas</b>,
      [
        'Fomenta cultura del ahorro',
        'Deducible hasta el 53% de las aportaciones',
        'Mejores prestaciones laborales',
      ],
      <b>Beneficios para los Participantes</b>,
      [
        'Se duplica el ahorro del empleado con la aportación que realiza la empresa (1:1)',
        'Préstamos a tasas de interés más bajas que el mercado',
        'Descuentos vía nómina',
        'Sin pago de ISR de intereses ganados por inversión',
        'Sin pago de IVA por los intereses del préstamo',
      ],
    ],
  },
  {
    id: 'fondo-de-pensiones',
    name: 'Fondo de pensiones (LISR Art. 29; RLISR. Art. 35)',
    headline: 'Planes privados de pensiones; complementarios al IMSS e ISSSTE',
    alt: 'Mejor atracción y retención de tu personal',
    graph: <FondoDePensionesChart />,
    details: [
      'Fondo de pensiones apegado a los artículos de ley LISR. Art. 29; RLISR. Art. 35',
      'Es una prestación por parte de la compañía al trabajador con el objetivo de proporcionar una jubilación o pensión al retiro, complementaria a las aportaciones al IMSS o al ISSSTE',
      'Las aportaciones al plan pueden ser del empleado, patrón o ambos',
      <b>Beneficios para las empresas</b>,
      [
        'Mejora el clima laboral',
        'Deducible hasta el 53% de las aportaciones',
        'Mejor atracción y retención de personal',
        'Genera competitividad contra el mercado',
        'Facilita el plan de sucesión',
      ],
      <b>Beneficios para los Participantes</b>,
      [
        'Fomenta cultura del ahorro para el retiro; generación AFORE',
        'Ahorro adicional al IMSS',
        'Recuperación de aportaciones vía declaración de impuestos',
        'Sin pago de ISR de intereses ganados por inversión',
        'Traspaso de aportaciones hacia un contrato de PPR en GBM, en caso de retirarse antes de la jubilación (Planes Personales de Retiro)',
      ],
    ],
  },
];
