import React from 'react';
import { Box, Padding, ContentBlock } from '@recordkeeping/common/build/layout';
import { FunctionComponentProps } from 'types';

export default function Header({ children }: FunctionComponentProps) {
  return (
    <header className="w-full top-0 z-50 bg-white bg-opacity-95 sticky backdrop-filter backdrop-blur">
      <ContentBlock width={[768, 768, 768, 1280]}>
        <Padding padding={['lg', 'xl']} paddingTop="xl" paddingBottom="xl">
          <Box flex={1} justify="between">
            {children}
          </Box>
        </Padding>
      </ContentBlock>
    </header>
  );
}

export function HeaderBackToTop({ children }: FunctionComponentProps) {
  function scrollToTop(e) {
    const key = e.keyCode || e.which;
    if (key != null && key !== 13) {
      return;
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <div
      className="cursor-pointer"
      role="button"
      tabIndex={0}
      onKeyPress={scrollToTop}
      onClick={scrollToTop}
    >
      {children}
    </div>
  );
}
