import { RefObject, useLayoutEffect, useState } from 'react';
import { useWindowScroll } from 'react-use';

export default function useReveal(
  ref: RefObject<HTMLElement>,
  threshold = 800,
) {
  const [isRevealed, setIsRevealed] = useState(false);
  const windowScroll = useWindowScroll();

  const captured = ref.current?.offsetTop
    ? windowScroll.y + threshold > ref.current.offsetTop
    : false;

  // let executed once
  useLayoutEffect(() => {
    if (!isRevealed && captured) {
      setIsRevealed(true);
    }
  }, [captured, isRevealed]);

  return isRevealed;
}
