import useMediaQuery from '@recordkeeping/common/build/useMediaQuery';

import {
  Columns,
  Column,
  Box,
  ContentBlock,
} from '@recordkeeping/common/build/layout';

import React, {
  ReactNode,
  MutableRefObject,
  createContext,
  useState,
  useMemo,
  useContext,
} from 'react';

import useStateRef from './useStateRef';

type ProductContentValue = {
  showDetails: boolean;
  toggleShowDetails: () => void;
  ref: MutableRefObject<HTMLElement | null>;
};

const ProductContext = createContext<ProductContentValue | undefined>(
  undefined,
);

export type ProductProps = {
  content: ReactNode;
  details: ReactNode;
  graph: ReactNode;
};

export default function Product({ content, details, graph }: ProductProps) {
  const [showDetails, setShowDetails] = useState(false);
  const [ref, hasRefChange, onRefChange] = useStateRef();
  const isXLarge = useMediaQuery('xl');

  function toggleShowDetails() {
    return setShowDetails((prevShowDetails) => !prevShowDetails);
  }

  const value = useMemo(
    () => ({ showDetails, toggleShowDetails, ref }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showDetails, hasRefChange],
  );

  return (
    <ProductContext.Provider value={value}>
      <ContentBlock width={['sm', 'md', 'xl']}>
        <section
          ref={onRefChange}
          /* exclude header height to make cover */
          style={{
            /* stylelint-disable-next-line value-keyword-case */
            height: isXLarge ? 'calc(100vh - 90px)' : '100%',
          }}
        >
          <Columns columns={[1, 1, 1, 2]}>
            <Column width={1}>{content}</Column>
            {showDetails ? (
              <Column width={1}>
                <Box height="full" align="center">
                  {details}
                </Box>
              </Column>
            ) : (
              <Column width={1}>
                <Box height="full" align="center">
                  {graph}
                </Box>
              </Column>
            )}
          </Columns>
        </section>
      </ContentBlock>
    </ProductContext.Provider>
  );
}

export function useProductContext() {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error(
      `Product compound components cannot be rendered outside the Product component`,
    );
  }

  return context;
}
