import 'moment/locale/es';
import moment from 'moment-timezone';
export var DEFAULT_DATE_TIME_FORMAT = 'DD-MM-yyyy';
var defaultDateTimeOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
};
// eslint-disable-next-line no-redeclare
export default function createDateFormatter(options, locales, timezone) {
    if (locales === void 0) { locales = ['es-MX']; }
    if (typeof options === 'string') {
        return {
            format: function (date) {
                return moment(date)
                    .tz(timezone !== null && timezone !== void 0 ? timezone : 'America/Mexico_City')
                    .locale(locales)
                    .format(options);
            },
        };
    }
    return new Intl.DateTimeFormat(locales, options !== null && options !== void 0 ? options : defaultDateTimeOptions);
}
