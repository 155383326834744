import React from 'react';
import { useMoneyFormatter } from '@recordkeeping/common/build/formatters';
import { Link } from '@recordkeeping/common/build/navigation';
import { Box, Stack } from '@recordkeeping/common/build/layout';
import { useTranslation } from '@recordkeeping/common/build/internationalization';
import { upperFirst } from '@recordkeeping/common/build/string';

import { useContractsValue } from 'contracts/ContractsProvider/ContractsProvider';

import ContractItem, { ContractItemWrapper } from './ContractItem';

export default function ResumeContracts() {
  const { data } = useContractsValue();
  const moneyFormatter = useMoneyFormatter();
  const t = useTranslation();

  return (
    <Box width="full">
      <Stack>
        <ContractItem
          left={upperFirst(t(`contracts`))}
          right={upperFirst(t(`current balance`))}
        />
        <div role="list" aria-label={upperFirst(t('contract list'))}>
          {data?.map((contract) => (
            <Link
              key={contract.id}
              role="listitem"
              href={`/contract/${contract.id}`}
            >
              <ContractItemWrapper
                aria-label={`${contract.name} ${contract.balance}`}
              >
                <ContractItem
                  left={contract.name}
                  right={
                    <span className="font-semibold">
                      {moneyFormatter.format(contract.balance)}
                    </span>
                  }
                />
              </ContractItemWrapper>
            </Link>
          ))}
        </div>
      </Stack>
    </Box>
  );
}
