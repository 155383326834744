import React, { useEffect, useState } from 'react';
import { ReactFunctionComponentProps } from '@recordkeeping/common/build/types';
import { upperFirst } from '@recordkeeping/common/build/string';
import { useIdle } from '@recordkeeping/common/build/hooks';
import { useTranslation } from '@recordkeeping/common/build/internationalization';

import Modal from 'components/Modal';
import SessionTimeoutModalContent from './SessionTimeoutModalContent';

const MODAL_TIMEOUT = 600_000; // 10 minutes

export default function SessionTimeoutModal({
  children,
}: ReactFunctionComponentProps) {
  const idle = useIdle(MODAL_TIMEOUT);
  const t = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (idle) {
      setIsOpen(true);
    }
  }, [idle]);

  function handleActionPress() {
    setIsOpen(false);
  }

  return (
    <>
      {children}
      <Modal
        isOpen={isOpen}
        aria-label={upperFirst(t('session timeout modal'))}
      >
        <SessionTimeoutModalContent onActionPress={handleActionPress} />
      </Modal>
    </>
  );
}
