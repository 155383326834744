import React from 'react';
import { useMoneyFormatter } from '@recordkeeping/common/build/formatters';
import { Stack, Padding } from '@recordkeeping/common/build/layout';

type BalanceHeaderProps = {
  label: string;
  loading?: boolean;
  amount: number;
};

export default function BalanceHeader({
  label,
  loading,
  amount,
}: BalanceHeaderProps) {
  const moneyFormatter = useMoneyFormatter();

  return (
    <Padding padding="none" paddingTop="xl" paddingBottom="xxl">
      <Stack space="md">
        {loading ? (
          <div
            data-testid="skeleton"
            className="skeleton skeleton--large filter invert"
          />
        ) : (
          <span id="resume-total-amount-label" className="text-body2">
            {label}
          </span>
        )}
        {loading ? (
          <div className="skeleton skeleton--input filter invert" />
        ) : (
          <span
            aria-labelledby="resume-total-amount-label"
            className="font-mabry text-h4 font-bold"
          >
            {moneyFormatter.format(amount)}
          </span>
        )}
      </Stack>
    </Padding>
  );
}
