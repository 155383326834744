import { useCallback } from 'react';
import { session } from '@recordkeeping/common/build/auth';
import { useEffectAfterMount } from '@recordkeeping/common/build/hooks';

import useRefreshTokenMutation from './useRefreshTokenMutation';

export default function useRefreshSession() {
  const [refreshTokenMutation, { error, isLoading, data }] =
    useRefreshTokenMutation();

  useEffectAfterMount(() => {
    if (data.token != null) {
      session.storeSession({
        token: data.token!,
        refreshToken: data.refreshToken!,
        identity: data.identity!,
      });
    }
  }, [data]);

  const refreshSession = useCallback(async () => {
    const refreshToken = session.getRefreshToken();
    refreshTokenMutation(refreshToken);
  }, [refreshTokenMutation]);

  return [refreshSession, { error, isLoading }] as const;
}
