import automapper from '@recordkeeping/common/build/automapper';
import { Identity, Role } from '@recordkeeping/common/build/auth';

import { PostAuthenticationReponse } from './api';

export function postAuthenticationReponseToIdentity(
  response: PostAuthenticationReponse,
): Identity {
  return automapper<PostAuthenticationReponse, Identity>(response, {
    id: (src) =>
      src.role_id === Role.PARTICIPANT ? src.participant_identifier : src.id,
    username: (src) => src.user_login,
    email: (src) => src.user_login,
    name: true,
    clientId: (src) => src.client_id,
    roleId: (src) => src.role_id,
  });
}
