var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { camelCase } from 'lodash';
import { createContext, useContext } from 'react';
import createHOC from './createHOC';
import { pascalCase } from './string';
export default function createContextSlice(initialValue, valueName) {
    if (valueName === void 0) { valueName = 'value'; }
    var BaseContext = createContext(undefined);
    var BaseProvider = BaseContext.Provider;
    var providerDisplayName = pascalCase(valueName + " provider");
    var contextDisplayName = camelCase(valueName + " context");
    var useValueDisplayName = camelCase("use " + valueName);
    function Provider(_a) {
        var value = _a.value, children = _a.children;
        var concreteValue = initialValue !== null && initialValue !== void 0 ? initialValue : value;
        if (concreteValue === undefined) {
            throw new Error(providerDisplayName + " was not provided with a concrete value");
        }
        return _jsx(BaseProvider, __assign({ value: concreteValue }, { children: children }), void 0);
    }
    function useValue() {
        var context = useContext(BaseContext);
        if (context === undefined) {
            throw new Error(useValueDisplayName + " must be used within a " + providerDisplayName);
        }
        return context;
    }
    var withProvider = createHOC(Provider);
    Provider.displayName = providerDisplayName;
    useValue.displayName = useValueDisplayName;
    BaseContext.displayName = contextDisplayName;
    return [Provider, useValue, BaseContext, withProvider];
}
