var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { Inline as InlineBase } from '@bedrock-layout/inline';
import useResponsiveProp from '../useResponsiveProp';
var InlineStyle = styled(InlineBase)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: inherit;\n"], ["\n  height: inherit;\n"])));
export default function Inline(_a) {
    var _b = _a.align, align = _b === void 0 ? 'start' : _b, children = _a.children, _c = _a.justify, justify = _c === void 0 ? 'start' : _c, _d = _a.space, space = _d === void 0 ? 'none' : _d;
    var spaceProp = useResponsiveProp(space);
    var alignProp = useResponsiveProp(align);
    var justifyProp = useResponsiveProp(justify);
    return (_jsx(InlineStyle, __assign({ gutter: spaceProp, align: alignProp, justify: justifyProp }, { children: children }), void 0));
}
var templateObject_1;
