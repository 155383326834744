import React from 'react';
import { Padding, ContentBlock } from '@recordkeeping/common/build/layout';
import { useTrack } from '@recordkeeping/common/build/analytics';

import Logo from 'components/Logo';

import Header, { HeaderGoToPortalLink, HeaderBackToTopButton } from './Header';
import Hero, { HeroContent } from './Hero';
import Indicators, { IndicatorCard } from './Indicators';
import ProductList from './ProductList';
import Footer from './Footer/Footer';

export default function Landing() {
  useTrack('Landing');

  return (
    <main className="relative">
      <Header>
        <HeaderBackToTopButton>
          <Logo />
        </HeaderBackToTopButton>
        <HeaderGoToPortalLink>Ingresar</HeaderGoToPortalLink>
      </Header>
      <section>
        <Hero>
          <HeroContent>
            Transparencia garantizada con el respaldo y la solidez del grupo
            GBM.
          </HeroContent>
        </Hero>
      </section>
      <section>
        <Padding padding={['lg', 'xl']} paddingTop="xxl" paddingBottom="xxl">
          <ProductList />
        </Padding>
      </section>
      <section style={{ backgroundColor: '#b3953c' }}>
        <ContentBlock width={[768, 768, 768, 1280]}>
          <Indicators>
            <IndicatorCard
              score={250}
              mask={(children) => <span>+{children}</span>}
              label="Clientes"
            />
            <IndicatorCard
              score={200}
              mask={(children) => <span>+{children}K</span>}
              label="Individualizaciones"
            />
          </Indicators>
        </ContentBlock>
      </section>
      <Footer />
    </main>
  );
}
