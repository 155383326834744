var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { MonitoringProvider } from './MonitoringContext';
import useNewRelic from './useNewRelic';
import localMonitoringClient from './localMonitoringClient';
export default function NewRelicProvider(_a) {
    var children = _a.children, _b = _a.enable, enable = _b === void 0 ? false : _b;
    var newRelic = useNewRelic(enable);
    // use fallback monitoring client when newRelic is not ready
    var value = useMemo(function () { return newRelic !== null && newRelic !== void 0 ? newRelic : localMonitoringClient; }, [newRelic]);
    return _jsx(MonitoringProvider, __assign({ value: value }, { children: children }), void 0);
}
