import React from 'react';
import { Box, Stack } from '@recordkeeping/common/build/layout';

import PageGutter from 'components/PageGutter';

import ResumeTotalAmount from './ResumeTotalAmount';
import ResumeContracts from './ResumeContracts';

export default function Resume() {
  return (
    <PageGutter>
      <Box direction="column" width="full">
        <ResumeTotalAmount />
        <Box width="full">
          <Stack space="lg">
            <ResumeContracts />
          </Stack>
        </Box>
      </Box>
    </PageGutter>
  );
}
