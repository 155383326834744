import times from 'lodash/times';
import memoize from '@recordkeeping/common/build/memoize';
import faker from 'faker';
import { rest } from '@recordkeeping/common/build/msw/server';

import { GetLoansResponse, GetPaymentScheduleResponse } from 'loans/api';

import createId from './createId';

export default [
  rest.get(
    'https://api.recordkeeping.dev-gbm.com/v2/loans',
    (req, res, ctx) => {
      const participantId = req.url.searchParams.get('participant_id')!;
      const contractId = req.url.searchParams.get('contract_id')!;
      const clientId = req.url.searchParams.get('client_id')!;
      const loans = times(10, (index) =>
        createLoanResponse({ participantId, contractId, clientId, index }),
      );
      return res(ctx.json(loans));
    },
  ),
  rest.get(
    'https://api.recordkeeping.dev-gbm.com/v2/loans/:loanId/payment-schedules',
    (req, res, ctx) => {
      const { loanId } = req.params;
      const paymentSchedules = times(10, (index) =>
        createPaymentScheduleResponse({ loanId, index }),
      );
      return res(ctx.json(paymentSchedules));
    },
  ),
];

type CreateLoanResponseOptions = {
  participantId: string;
  index: number;
  contractId?: string;
  clientId?: string;
};

export const createLoanResponse = memoize(
  ({
    participantId,
    clientId = createId(),
    contractId = createId(),
  }: CreateLoanResponseOptions): GetLoansResponse => {
    return {
      id: createId(),
      yield: parseFloat(faker.finance.amount(1, 20, 2)) / 100,
      status: 'pending',
      requested_at: faker.datatype.datetime(),
      expected_at: faker.datatype.datetime(),
      participant_id: participantId,
      kind: '',
      contract_id: contractId,
      client_id: clientId,
      amount: parseFloat(faker.finance.amount(1, 999_999, 2)),
    };
  },
);

type CreatePaymentScheduleResponseOptions = {
  loanId: string;
  index: number;
};

export const createPaymentScheduleResponse = memoize(
  ({
    loanId,
  }: CreatePaymentScheduleResponseOptions): GetPaymentScheduleResponse => {
    const amount = parseFloat(faker.finance.amount(1, 99, 2));
    const interest = amount * 0.0066666667;

    return {
      amount,
      interest,
      total: interest + amount,
      status: 'pending',
      pending_balance: parseFloat(faker.finance.amount(1, 99, 2)),
      loan_id: loanId,
      id: createId(),
      due_date: new Date(),
    };
  },
);
