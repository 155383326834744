import React from 'react';

import BalanceHeader from 'components/BalanceHeader';
import { useContractResumeContext } from './ContractResumeContext';

export default function ContractResumeHeader() {
  const { contract, isLoading } = useContractResumeContext();

  return (
    <BalanceHeader
      loading={isLoading}
      label={contract?.name || ''}
      amount={contract?.balance || 0}
    />
  );
}
