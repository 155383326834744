import { Account } from '../types';

const initialState = {
  initialBalance: 0,
  yield: 0,
  interest: 0,
  debit: 0,
  credit: 0,
  balance: 0,
};

export default function getAccountsResume(
  accounts: Array<Account> | undefined,
) {
  if (accounts == null) return initialState;
  return accounts.reduce((acc, account) => {
    const initialBalance =
      +account.accountMasterTypeId === 54 ? account.initialBalance : 0;
    return {
      initialBalance: acc.initialBalance + initialBalance,
      yield: acc.yield + account.yield,
      interest: acc.interest + account.interest,
      debit: acc.debit + account.debit,
      credit: acc.credit + account.credit,
      balance: acc.balance + account.balance,
    };
  }, initialState);
}
