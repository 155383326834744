import faker from 'faker';
import memoize from '@recordkeeping/common/build/memoize';

import { GetContractResponse } from 'contracts/api';

import createId from '../createId';

export type CreateContractResponseOptions = {
  participantId: string;
  index: number;
};

export const createContractResponse = memoize(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (args: any): GetContractResponse => {
    return {
      id: createId(),
      type: createId(),
      name: faker.commerce.productName(),
      client_id: createId(),
      balance: parseFloat(faker.finance.amount(0, 1_000_000, 2)),
      balance_date: faker.datatype.datetime().toISOString(),
    };
  },
);
