import React, { useMemo } from 'react';
import { useMedia } from 'react-use';
import { useSprings, animated, to } from '@react-spring/web';
import times from 'lodash/times';
import useResponsiveProp from '@recordkeeping/common/build/useResponsiveProp';

import useReveal from 'landing/useReveal';

import { useProductContext } from './Product';

const radian = 0.01745329;
const levels = [1, 6, 12, 18, 24];

export default function CajaDeAhorroChart() {
  const { ref } = useProductContext();
  const isRevealed = useReveal(ref);
  const springs = useCircleRaildAnimation(isRevealed);
  const height = useResponsiveProp(['20rem', '25rem', '39rem']);

  return (
    <div className="w-full relative" style={{ height }}>
      {springs.map(({ transformX, transformY }, index) => (
        <animated.div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className="absolute"
          style={{
            top: 'calc(50% - 10px)',
            left: 'calc(50% - 10px)',
            transform: to([transformX, transformY], (x, y) => {
              return `translate(${x}px, ${y}px)`;
            }),
          }}
        >
          <Circle />
        </animated.div>
      ))}
    </div>
  );
}

function Circle() {
  const size = useCircleSize();
  return (
    <div style={{ height: size, width: size }}>
      <div className="w-full h-full bg-secondary-green rounded-full" />
    </div>
  );
}

function useCircleRaildAnimation(enabled: boolean = true) {
  const size = useCircleSize();
  const prefersReducedMotion = useMedia('(prefers-reduced-motion)');
  const configs = useMemo(() => {
    return levels.flatMap((items, level) => {
      const degPerItem = 360 / items;
      return times(items, (index) => {
        const deg = degPerItem * index;
        const x = level * (size * 1.2) * Math.cos(deg * radian);
        const y = level * (size * 1.2) * Math.sin(deg * radian);

        return {
          pause: !enabled,
          delay: 75 * index,
          from: { transformX: 0, transformY: 0 },
          immediate: prefersReducedMotion,
          to: {
            transformX: x,
            transformY: y,
          },
        };
      });
    });
  }, [enabled, size, prefersReducedMotion]);

  return useSprings(configs.length, configs);
}

function useCircleSize() {
  return useResponsiveProp([20, 40, 50]);
}
