import React from 'react';
import { Box, Padding, ContentBlock } from '@recordkeeping/common/build/layout';
import { FunctionComponentProps } from 'types';

export default function HeroContent({ children }: FunctionComponentProps) {
  return (
    <Box height="full" align="center">
      <ContentBlock width={[768, 768, 768, 1280]}>
        <Padding padding="none" paddingRight={['lg', 'xl']}>
          <Box height="full" align="center" justify="end">
            <h3 className="m-0 w-full xl:w-2/3 text-white text-3xl md:text-6xl font-mabry text-right">
              <span className="leading-relaxed">{children}</span>
            </h3>
          </Box>
        </Padding>
      </ContentBlock>
    </Box>
  );
}
