export default function createMoneyFormatter(currency, locales) {
    if (currency === void 0) { currency = 'MXN'; }
    if (locales === void 0) { locales = ['en-US', 'es-MX']; }
    if (currency === 'MXN') {
        return custumMoneyFormatWithSuffix(currency);
    }
    return new Intl.NumberFormat(locales, {
        currency: currency,
        style: 'currency',
    });
}
function custumMoneyFormatWithSuffix(currency) {
    return {
        format: function (value) {
            return createMoneyFormatter('USD').format(value) + " " + currency;
        },
    };
}
