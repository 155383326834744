import React from 'react';
import { Cover } from '@recordkeeping/common/build/layout';

export default function LoadingScreen() {
  return (
    <Cover height="100vh">
      <div className="w-full animate__animated animate__fadeIn loader loader--animated" />
    </Cover>
  );
}
