import { useState } from 'react';
import useInterval from 'react-use/lib/useInterval';
var INTERVAL = 100;
export default function useNewRelic(enable) {
    var _a = useState(), newRelic = _a[0], setNewRelic = _a[1];
    // when is enabled, wait for the `window.newrelic` object to be available
    // it will be set when the script located in the public folder is loaded
    // check `index.html` and `public/` to see the details
    useInterval(function () {
        // @ts-ignore
        if (window.newrelic != null) {
            var client = {
                // @ts-ignore
                notifyError: window.newrelic.noticeError,
            };
            setNewRelic(client);
        }
    }, enable && newRelic == null ? INTERVAL : null);
    return newRelic;
}
