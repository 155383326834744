import { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { postRefreshToken, PostAuthenticationReponse } from './api';
import * as mapper from './mapper';

export default function useRefreshTokenMutation() {
  const queryClient = useQueryClient();

  const {
    mutateAsync,
    data: response,
    error,
    isLoading,
  } = useMutation<PostAuthenticationReponse, Error, string>(postRefreshToken, {
    onMutate: async () => {
      await queryClient.cancelQueries();
    },
    onSettled: async () => {
      // HACK: let the session to be refreshed before fetching the queries
      setTimeout(() => {
        queryClient.invalidateQueries();
      }, 100);
    },
  });

  const refreshTokenMutation = useCallback(
    async (refreshToken: string) => {
      return mutateAsync(refreshToken);
    },
    [mutateAsync],
  );

  const data = useMemo(() => {
    return {
      token: response?.token,
      refreshToken: response?.refresh_token,
      identity: response
        ? mapper.postAuthenticationReponseToIdentity(response)
        : undefined,
    };
  }, [response]);

  return [
    refreshTokenMutation,
    {
      data,
      error,
      isLoading,
    },
  ] as const;
}
