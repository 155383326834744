import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import 'moment/locale/es';
import moment from 'moment-timezone';
moment.locale('es');
moment.tz.setDefault('America/Mexico_City');
i18n
    .use(initReactI18next)
    .use(HttpApi)
    .use(LanguageDetector)
    .init({
    lng: 'es-MX',
    fallbackLng: ['es-MX'],
    backend: {
        loadPath: (process.env.PUBLIC_PATH || '/') + "locales/{{lng}}/{{ns}}.json",
    },
    ns: [
        'common',
        'authentication',
        'cash',
        'clients',
        'lorem',
        'recordkeeping',
    ],
    defaultNS: 'common',
    interpolation: {
        escapeValue: false,
        format: function (value, format) {
            if (moment.isMoment(value)) {
                return value.format(format);
            }
            return value;
        },
    },
    react: {
        wait: true,
    },
}, function () {
    moment.updateLocale(moment.locale(), {
        invalidDate: i18n.t('phrases.notAvailable'),
        monthsShort: i18n.t('i18n.monthsShort').split('_'),
    });
});
export default i18n;
