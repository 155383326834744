import React, { useCallback } from 'react';
import { Box, Stack, Inline } from '@recordkeeping/common/build/layout';
import { upperFirst } from '@recordkeeping/common/build/string';
import { useAuth } from '@recordkeeping/common/build/auth';
import { useHistory } from '@recordkeeping/common/build/navigation';
import { useTimeout } from '@recordkeeping/common/build/hooks';
import { useTranslation } from '@recordkeeping/common/build/internationalization';
import { useSpring, animated } from '@react-spring/web';
import { useMedia } from 'react-use';

const SESSION_TIMEOUT = 300_000; // 5 minutes

type SessionTimeoutModalContentProps = {
  onActionPress: () => void;
};

export default function SessionTimeoutModalContent({
  onActionPress,
}: SessionTimeoutModalContentProps) {
  const auth = useAuth();
  const history = useHistory();
  const t = useTranslation();

  const handleRefreshSession = useCallback(() => {
    auth.refreshSession!();
    onActionPress();
  }, [auth.refreshSession, onActionPress]);

  const handleSignOff = useCallback(() => {
    auth.logout();
    history.replace('/auth');
    onActionPress();
  }, [auth, history, onActionPress]);

  // remove session if the user is idle for more than 10 minutes
  useTimeout(handleSignOff, SESSION_TIMEOUT);

  return (
    <Stack space="lg">
      <span className="text-center">
        {t(
          'we have not noticed activity in your session, and it will expire in',
          { transform: 'upperFirst' },
        )}
      </span>
      <Countdown />
      <span className="text-center">
        {t('what do you want to do?', { transform: 'upperFirst' })}
      </span>
      <Box justify="center" align="center">
        <Inline space="md">
          <button
            className="button button--small button--light"
            onClick={handleSignOff}
            type="button"
          >
            {upperFirst(t('sign off'))}
          </button>
          <button
            className="button button--small button--dark"
            onClick={handleRefreshSession}
            type="button"
          >
            {upperFirst(t('keep session'))}
          </button>
        </Inline>
      </Box>
    </Stack>
  );
}

function Countdown() {
  const prefersReducedMotion = useMedia('(prefers-reduced-motion)');
  const { number } = useSpring({
    from: { number: 300 },
    to: { number: 0 },
    immediate: prefersReducedMotion,
    config: {
      duration: 300000,
    },
  });

  return (
    <div className="bg-gray-200 rounded text-center text-xl font-bold">
      <animated.span>
        {number.to((n) => {
          const minutes = Math.floor(n / 60);
          const seconds = n % 60;
          return `${minutes.toFixed(0).toString().padStart(2, '0')}:${seconds
            .toFixed(0)
            .toString()
            .padStart(2, '0')}`;
        })}
      </animated.span>
    </div>
  );
}
