import accounts from './accounts/handlers';
import contracts from './contracts/handlers';
import loans from './loans';
import participants from './participants/handlers';
import reports from './reports/handlers';
import rules from './rules/handlers';

export default [
  ...accounts,
  ...contracts,
  ...loans,
  ...participants,
  ...reports,
  ...rules,
];
