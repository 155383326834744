import { Allowed, Authenticated, Role } from '@recordkeeping/common/build/auth';
import { Helmet as Head } from 'react-helmet';
import React, { Suspense, lazy } from 'react';
import Route from '@recordkeeping/common/build/navigation/Route';
import Router from '@recordkeeping/common/build/navigation/Router';

import Landing from 'landing/Landing';
import LoadingScreen from 'components/LoadingScreen';
import Login from 'login/Login';
import SessionTimeoutModal from 'login/SessionTimeoutModal';
import ContractsQueryProvider from 'contracts/ContractsProvider/ContractsQueryProvider';

import { RESUME_PATH, CONTRACTS_RESUME_PATH } from 'resume/constants';

import {
  LOANS_PATH,
  LOAN_APPLICATION_PATH,
  PAYMENT_SCHEDULE_PATH,
} from 'loans/constants';

import InfrastructureProvider from './InfrastructureProvider';
import { REPORTS_PATH } from './reports/constants';

const Resume = lazy(() => import('resume/Resume'));
const Report = lazy(() => import('./reports/Reports'));
const ContractResume = lazy(() => import('resume/ContractResume'));
const Dashboard = lazy(() => import('dashboard/Dashboard'));
const Loans = lazy(() => import('loans/Loans/Loans'));
const LoanApplication = lazy(
  () => import('loans/LoanApplication/LoanApplication'),
);
const PaymentSchedule = lazy(
  () => import('loans/PaymentSchedule/PaymentSchedule'),
);

export default function App() {
  return (
    <>
      <Head>
        <title>Record Keeping | Grupo Bursátil Mexicano</title>
      </Head>
      <Suspense fallback={<LoadingScreen />}>
        <InfrastructureProvider>
          <Router>
            <Route exact path="/" component={Landing} />
            <Route exact path="/auth" component={Login} />
            <SessionTimeoutModal>
              <Authenticated redirect="/auth">
                <Dashboard>
                  <ContractsQueryProvider>
                    <Route exact path={RESUME_PATH} component={Resume} />
                    <Route
                      exact
                      path={CONTRACTS_RESUME_PATH}
                      component={ContractResume}
                    />
                    <Allowed role={Role.PARTICIPANT}>
                      <Route exact path={LOANS_PATH} component={Loans} />
                      <Route
                        exact
                        path={LOAN_APPLICATION_PATH}
                        component={LoanApplication}
                      />
                      <Route
                        exact
                        path={PAYMENT_SCHEDULE_PATH}
                        component={PaymentSchedule}
                      />
                    </Allowed>
                    <Route exact path={REPORTS_PATH} component={Report} />
                  </ContractsQueryProvider>
                </Dashboard>
              </Authenticated>
            </SessionTimeoutModal>
          </Router>
        </InfrastructureProvider>
      </Suspense>
    </>
  );
}
