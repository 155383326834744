var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useIdentity } from './AuthContext';
export default function Allowed(_a) {
    var children = _a.children, role = _a.role, props = __rest(_a, ["children", "role"]);
    var identity = useIdentity().identity;
    if ((identity === null || identity === void 0 ? void 0 : identity.roleId) !== role)
        return null;
    return React.Children.map(children, function (child) {
        return React.cloneElement(child, props);
    });
}
