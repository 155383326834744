import times from 'lodash/times';
import { rest } from '@recordkeeping/common/build/msw/server';

import { createContractResponse } from './factories';

export default [
  rest.get(
    'https://api.recordkeeping.dev-gbm.com/v2/contracts',
    (req, res, ctx) => {
      const contracts = times(2, (index) => createContractResponse({ index }));
      return res(ctx.json(contracts));
    },
  ),
  rest.get(
    'https://api.recordkeeping.dev-gbm.com/v2/participants/:participantId/contracts',
    (req, res, ctx) => {
      const { participantId } = req.params;
      const contracts = times(2, (index) =>
        createContractResponse({ participantId, index }),
      );

      return res(ctx.json(contracts));
    },
  ),
];
