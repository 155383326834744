import React, { useMemo } from 'react';
import { animated, useSprings, SpringValue } from '@react-spring/web';
import { useMedia } from 'react-use';
import times from 'lodash/times';
import { FunctionComponentProps } from 'types';

export type UseFadeInUpSpringOptions = {
  enabled?: boolean;
  delay: number;
  size: number;
};

type FadeInUpSpringValue = {
  opacity: SpringValue<number>;
  transform: SpringValue<number>;
};

export default function useFadeInUpSpring({
  enabled = true,
  delay,
  size,
}: UseFadeInUpSpringOptions) {
  const prefersReducedMotion = useMedia('(prefers-reduced-motion)');
  const items = useMemo(() => {
    return times(size, (index) => {
      return {
        delay: delay * (index + 1),
        immediate: prefersReducedMotion,
        pause: !enabled,
        from: {
          opacity: 0,
          transform: 50,
        },
        to: {
          opacity: 1,
          transform: 0,
        },
        config: {
          easing: (t) =>
            t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
        },
      };
    });
  }, [delay, enabled, prefersReducedMotion, size]);

  return useSprings(size, items);
}

export type FadeInBlockProps = FunctionComponentProps<{
  springValue: FadeInUpSpringValue;
}>;

export function FadeInBlock({ children, springValue }: FadeInBlockProps) {
  return (
    <animated.span
      className="block"
      style={{
        opacity: springValue.opacity.to((x) => x),
        transform: springValue.transform.to((y) => `translate(0, ${y}px)`),
      }}
    >
      {children}
    </animated.span>
  );
}
