import React, { useRef } from 'react';
import { Columns, Column, Padding } from '@recordkeeping/common/build/layout';

import { FunctionComponentProps } from 'types';

import useReveal from './useReveal';
import useCountUpInterpolation, {
  CountUpNumber,
} from './useCountUpInterpolation';

export default function Indicators({ children }: FunctionComponentProps) {
  return (
    <Padding
      padding="none"
      paddingTop={['none', 'xxl']}
      paddingBottom={['none', 'xxl']}
    >
      <Columns columns={[1, 2]} space="xl">
        {children}
      </Columns>
    </Padding>
  );
}

type IndicatorCardProps = {
  score: number;
  mask: (n: any) => any;
  label: string;
};

export function IndicatorCard({ score, mask, label }: IndicatorCardProps) {
  const ref = useRef(null);
  const isRevealed = useReveal(ref, 1400);
  const interpolation = useCountUpInterpolation(score, isRevealed);

  return (
    <Column width={1}>
      <article
        ref={ref}
        className="w-full cursor-default text-center text-white"
      >
        <Padding padding="lg" paddingTop="xxl" paddingBottom="xxl">
          <div className="m-0 text-7xl md:text-8xl font-mabry leading-normal">
            {mask(<CountUpNumber>{interpolation}</CountUpNumber>)}
          </div>
          <p className="m-0 text-2xl md:text-4xl">{label}</p>
        </Padding>
      </article>
    </Column>
  );
}
