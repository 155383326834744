/* eslint-disable camelcase */
import { decode } from '../jwt';
export default function decodeAuthToken(token) {
    var data = decode(token);
    return {
        exp: data.exp,
        username: data.given_name || data['cognito:username'],
        email: data.given_email || data['cognito:email'] || data.email,
    };
}
