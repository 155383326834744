export const REPORTS_PATH = '/reports';

// TODO: migrate to parameter store
export const ESTADO_DE_CUENTA_INDIVIDUAL_ID = '3';
export const SALDOS_HISTORICOS_ID = '7';
export const SALDOS_NETOS_ID = '8';
export const ESTADO_DE_CUENTA_INTEGRAL_ID = '2';
export const ESTADO_DE_CUENTA_INDIVIDUAL_DERECHO_ADQUIRIDO_CONSOLIDADO_ID =
  '22';

export const ENABLED_REPORTS = new Set([
  SALDOS_HISTORICOS_ID,
  SALDOS_NETOS_ID,
  ESTADO_DE_CUENTA_INTEGRAL_ID,
]);

export const PARTICIPANT_DEPENDENT_REPORTS = new Set([
  ESTADO_DE_CUENTA_INDIVIDUAL_ID,
  ESTADO_DE_CUENTA_INDIVIDUAL_DERECHO_ADQUIRIDO_CONSOLIDADO_ID,
]);
