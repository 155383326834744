import React from 'react';
import { ReactFunctionComponentProps } from '@recordkeeping/common/build/types';
import { useParams } from '@recordkeeping/common/build/navigation';

import { useContractsValue } from 'contracts/ContractsProvider/ContractsProvider';

import useParticipantAccounts from './useParticipantAccounts';
import { ContractResumeProvider as BaseContractResumeProvider } from './ContractResumeContext';

export default function ContractResumeProvider({
  children,
}: ReactFunctionComponentProps) {
  const params = useParams<Record<string, string>>();
  const { data } = useContractsValue();
  const contract = data?.find((c) => c.id === params.contractId);
  const { accounts, isLoading, error } = useParticipantAccounts({
    contractId: contract?.id,
    initDate: contract?.createdAt.toISOString(),
  });

  const value = {
    contract,
    accounts,
    isLoading,
    error,
  };

  return (
    <BaseContractResumeProvider value={value}>
      {children}
    </BaseContractResumeProvider>
  );
}
