import React from 'react';
import { useMoneyFormatter } from '@recordkeeping/common/build/formatters';
import { Padding, Box } from '@recordkeeping/common/build/layout';

type SummaryItemProps = {
  'aria-label': string;
  label: string;
  amount: number;
  amountBold?: boolean;
  labelBold?: boolean;
  nonUnderlined?: boolean;
};

export default function SummaryItem({
  'aria-label': ariaLabel,
  label,
  amount,
  amountBold = false,
  labelBold = false,
  nonUnderlined = false,
}: SummaryItemProps) {
  const moneyFormatter = useMoneyFormatter();
  let amountStyle = 'text-body2 font-bold';
  let labelStyle = 'text-body2 font-bold';
  let boxStyle = 'border-0 border-b border-gray-200 border-solid';
  if (!amountBold) {
    amountStyle = 'text-body2';
  }
  if (!labelBold) {
    labelStyle = 'text-body2';
  }
  if (nonUnderlined) {
    boxStyle = '';
  }

  return (
    <div className={boxStyle}>
      <Padding padding="md" paddingTop="lg" paddingBottom="lg">
        <Box justify="between" align="center">
          <span className={labelStyle}>{label}</span>
          <span className={amountStyle} aria-label={ariaLabel}>
            {moneyFormatter.format(amount)}
          </span>
        </Box>
      </Padding>
    </div>
  );
}
