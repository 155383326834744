import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { ReactFunctionComponentProps } from '@recordkeeping/common/build/types';

export type ModalProps = ReactFunctionComponentProps & {
  isOpen: boolean;
  'aria-label'?: string;
};

export default function Modal({
  children,
  isOpen,
  'aria-label': ariaLabel,
}: ModalProps) {
  useEffect(() => {
    ReactModal.setAppElement('#root');
  }, []);

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel={ariaLabel}
      style={{
        overlay: {
          display: 'flex',
          zIndex: '9999',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '40px',
        },
        content: {
          zIndex: '9999',
          border: 'none',
          minWidth: '320px',
          maxWidth: '100%',
          maxHeight: '100%',
          position: 'initial',
          background: 'white',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '40px',
        },
      }}
    >
      {children}
    </ReactModal>
  );
}
