import I18nextProvider from '@recordkeeping/common/build/internationalization/I18nextProvider';
import MixpanelProvider from '@recordkeeping/common/build/analytics/MixpanelProvider';
import NewRelicProvider from '@recordkeeping/common/build/monitoring/NewRelicProvider';
import QueryProvider from '@recordkeeping/common/build/QueryProvider';
import React, { ReactNode } from 'react';
import RollOutProvider from '@recordkeeping/common/build/featureFlags/RollOutProvider';
import useConfig from '@recordkeeping/common/build/config';

import LoadingScreen from 'components/LoadingScreen';
import BasicAuthProvider from 'login/BasicAuthProvider';

type InfrastructureProviderProps = {
  children: ReactNode;
};

export default function InfrastructureProvider({
  children,
}: InfrastructureProviderProps) {
  const config = useConfig();

  return (
    <I18nextProvider>
      <RollOutProvider token={config.ROLLOUT_KEY}>
        <MixpanelProvider
          enable={config.APP_ENV === 'prd' || config.ENABLE_MIXPANEL}
          fallback={<LoadingScreen />}
          token={config.MIXPANEL_TOKEN}
        >
          <NewRelicProvider
            enable={config.APP_ENV === 'prd' || config.ENABLE_NEW_RELIC}
          >
            <QueryProvider debug={config.DEBUG_HTTP}>
              <BasicAuthProvider>{children}</BasicAuthProvider>
            </QueryProvider>
          </NewRelicProvider>
        </MixpanelProvider>
      </RollOutProvider>
    </I18nextProvider>
  );
}
