/* eslint-disable camelcase */
import api, { createCancelTokenFromSignal } from 'client';
import { mapKeys, snakeCaseStrategy } from '@recordkeeping/common/build/json';

export type GetAccountsPathParams = {
  contractId: string;
};

export type GetParticipantAccountsQueryParams = {
  initDate: string;
};

export type GetAccountResponse = {
  id: string;
  account_master_type_id: string;
  yield: number;
  name: string;
  interest: number;
  initial_balance: number;
  debit: number;
  credit: number;
  balance: number;
};

export async function getParticipantAccounts(
  pathParams: GetAccountsPathParams,
  queryParams: GetParticipantAccountsQueryParams,
  signal?: AbortSignal,
) {
  const cancelToken = createCancelTokenFromSignal(signal);
  const response = await api.get<Array<GetAccountResponse>>(
    `/contracts/${pathParams.contractId}/accounts`,
    {
      cancelToken,
      params: mapKeys(queryParams, snakeCaseStrategy),
    },
  );

  return response.data;
}
