import { useMemo } from 'react';
import { useQuery } from 'react-query';

import api, { createCancelTokenFromSignal } from 'client';
import { Contract } from 'contracts/types';

export async function getContracts(signal?: AbortSignal) {
  const cancelToken = createCancelTokenFromSignal(signal);
  const response = api.get(`/contracts`, { cancelToken });

  return response.then((res) =>
    res.data.map((item) => {
      return {
        id: item.id,
        type: item.type,
        name: item.name,
        clientId: item.client_id,
        balance: item.balance,
        createdAt: new Date(item.balance_date),
      };
    }),
  );
}

export const USE_CONTRACTS_QUERY_KEY = 'participants';

export default function useContractsQuery() {
  const { data, isLoading, error, refetch } = useQuery<
    ReadonlyArray<Contract>,
    Error | null
  >(USE_CONTRACTS_QUERY_KEY, ({ signal }) => getContracts(signal));

  return useMemo(
    () => ({ data, isLoading, error, refetch }),
    [data, isLoading, error, refetch],
  );
}
