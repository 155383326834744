/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { upperFirst } from '@recordkeeping/common/build/string';
import { useTranslation } from '@recordkeeping/common/build/internationalization';
import { Box, Stack, Padding } from '@recordkeeping/common/build/layout';
import { useDateFormatter } from '@recordkeeping/common/build/formatters';
import SummaryItem from './SummaryItem';

import { useContractResumeContext } from './ContractResumeContext';
import getAccountsResume from './getAccountsResume';
import AccountGroupDetail from './AccountGroupDetail';

export default function AccountsResume() {
  const t = useTranslation();
  const { accounts, contract } = useContractResumeContext();
  const resume = getAccountsResume(accounts);
  const currentDate = new Date();
  let dateFrom = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const dateFormatter = useDateFormatter({
    dateStyle: 'medium',
  });

  if (contract?.createdAt) {
    dateFrom = new Date(
      contract.createdAt.getFullYear(),
      contract.createdAt.getMonth(),
      contract.createdAt.getDate(),
    );
  }

  return (
    <Stack space="xl">
      <Stack space="lg">
        <Padding padding="md">
          <Box width="full" justify="between">
            <p className="text-body1 text-center w-full">
              {upperFirst(
                t(`summary from {{dateFrom}} to {{dateTo}}`, {
                  dateTo: dateFormatter.format(currentDate),
                  dateFrom: dateFormatter.format(dateFrom),
                }),
              )}
            </p>
          </Box>
        </Padding>

        <Stack space="md">
          <SummaryItem
            aria-label="Initial balance"
            label={upperFirst(t('initial balance'))}
            amount={resume.initialBalance}
            labelBold
            amountBold
          />

          {accounts != null ? <AccountGroupDetail accounts={accounts} /> : null}

          <SummaryItem
            aria-label="Final balance"
            label={upperFirst(t('final balance'))}
            amount={resume.balance}
            labelBold
            amountBold
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
