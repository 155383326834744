import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import useMediaQuery from '../useMediaQuery';
import useResponsiveProp from '../useResponsiveProp';
export default function Hidden(_a) {
    var children = _a.children, above = _a.above, below = _a.below;
    var aboveProp = useResponsiveProp(above);
    var belowProp = useResponsiveProp(below);
    var isAbove = useMediaQuery(aboveProp);
    var isBelow = useMediaQuery(belowProp);
    if (above !== undefined && below !== undefined && isAbove && isBelow) {
        return _jsx(_Fragment, { children: children }, void 0);
    }
    if (above !== undefined && isAbove) {
        return _jsx(_Fragment, { children: children }, void 0);
    }
    if (below !== undefined && isBelow) {
        return _jsx(_Fragment, { children: children }, void 0);
    }
    return _jsx(_Fragment, { children: null }, void 0);
}
