import React from 'react';
import { Stack, Padding, Box } from '@recordkeeping/common/build/layout';
import { useTrackEvent } from '@recordkeeping/common/build/analytics';

import useFadeInUpSpring, { FadeInBlock } from './useFadeInUpSpring';
import useReveal from '../useReveal';
import { useProductContext } from './Product';

export type ProductContentProps = {
  alt: string;
  headline: string;
  name: string;
};

export default function ProductContent({
  alt,
  headline,
  name,
}: ProductContentProps) {
  const { showDetails, toggleShowDetails, ref } = useProductContext();
  const trackEvent = useTrackEvent('See more', {
    tag: 'Product',
    name,
    open: (!showDetails).toString(),
  });
  const isRevealed = useReveal(ref);
  const springs = useFadeInUpSpring({
    size: 4,
    delay: 250,
    enabled: isRevealed,
  });

  function handleSeeMoreClick() {
    toggleShowDetails();
    trackEvent();
  }

  return (
    <span ref={ref}>
      <Box height="full">
        <Padding
          padding="lg"
          paddingTop={['xl', 'xxl']}
          paddingBottom={['xl', 'xxl']}
        >
          <Box align="center">
            <Stack space="xl">
              <FadeInBlock springValue={springs[0]}>
                <h3 className="m-0 text-body1 font-normal">{name}</h3>
              </FadeInBlock>
              <h4 className="m-0 text-h4 md:text-h1 font-mabry font-normal">
                <FadeInBlock springValue={springs[1]}>{headline}</FadeInBlock>
              </h4>
              <p className="m-0 text-h5 font-normal">
                <FadeInBlock springValue={springs[2]}>{alt}</FadeInBlock>
              </p>
              <FadeInBlock springValue={springs[3]}>
                <button
                  type="button"
                  onClick={handleSeeMoreClick}
                  className="button-fab button-fab--next button-fab--with-description space-x-2 hover:space-x-4"
                >
                  <span>Quiero saber mas</span>
                  <div className="button-fab transition-spacing" />
                </button>
              </FadeInBlock>
            </Stack>
          </Box>
        </Padding>
      </Box>
    </span>
  );
}
