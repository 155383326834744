/* eslint-disable guard-for-in */
import hasKey from 'lodash/has';
export default function automapper(src, config) {
    var target = {};
    // automapp same keys
    for (var key in src) {
        var keyString = key;
        if (hasKey(config, key)) {
            var mapFn = config[keyString];
            if (typeof mapFn !== 'function') {
                target[keyString] = src[keyString];
            }
        }
    }
    // map missing keys, if mapFn was not provided
    for (var key in config) {
        var keyString = key;
        var mapFn = config[keyString];
        if (typeof mapFn === 'function') {
            target[keyString] = mapFn(src);
        }
    }
    return target;
}
