import React from 'react';
import clsx from 'clsx';

import useFadeInUpSpring, { FadeInBlock } from './useFadeInUpSpring';

export type DetailListProps = {
  details: Array<string | JSX.Element | Array<string>>;
};

export function DetailList({ details }: DetailListProps) {
  const ulClassName = clsx('p-0 list-inside space-y-2');
  const transGroup = useFadeInUpSpring({
    size: details.length,
    delay: 50,
  });

  return (
    <ul className={ulClassName}>
      {details.map((detail, index) => {
        const transition = transGroup[index];
        if (typeof detail === 'string') {
          return (
            <FadeInBlock key={detail} springValue={transition}>
              <li>{detail}</li>
            </FadeInBlock>
          );
        }

        // @ts-ignore
        if (typeof detail.map !== 'function') {
          const node = detail as JSX.Element;
          return (
            <FadeInBlock
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              springValue={transition}
            >
              <li>{node}</li>
            </FadeInBlock>
          );
        }

        const items = detail as Array<string>;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <ul key={index} className={ulClassName}>
            <FadeInBlock springValue={transition}>
              {items.map((inner) => {
                return <li key={inner}>{inner}</li>;
              })}
            </FadeInBlock>
          </ul>
        );
      })}
    </ul>
  );
}
