import 'normalize.css';
import 'animate.css';
import 'gbm-guidelines-2.0/dist/guidelines.min.css';
import 'tailwindcss/components.css';
import 'tailwindcss/utilities.css';

import './styles/reset.css';

import React from 'react';
import ReactDOM from 'react-dom';
import getConfig from '@recordkeeping/common/build/config/config';

import mswWorker from './msw/browser';
import App from './App';
import reportWebVitals from './reportWebVitals';

const config = getConfig();

if (config.USE_MSW) {
  mswWorker.start();
} else {
  mswWorker.stop();
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
