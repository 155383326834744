var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
var queryClient = createQueryClient();
export default function QueryProvider(_a) {
    var children = _a.children, _b = _a.debug, debug = _b === void 0 ? false : _b, _c = _a.client, client = _c === void 0 ? queryClient : _c;
    return (_jsxs(QueryClientProvider, __assign({ client: client }, { children: [children,
            debug ? _jsx(ReactQueryDevtools, { initialIsOpen: false }, void 0) : null] }), void 0));
}
export function createQueryClient() {
    return new QueryClient({
        defaultOptions: {
            queries: {
                retryDelay: function (attempt) {
                    return Math.min(attempt > 1 ? Math.pow(2, attempt) * 1000 : 1000, 30 * 1000);
                },
            },
        },
    });
}
