import React from 'react';
import { useSpring, animated, Interpolation } from '@react-spring/web';
import { useMedia } from 'react-use';

const DELAY = 200;

export default function useCountUpSpring(
  upTo: number,
  enabled: boolean = true,
) {
  const prefersReducedMotion = useMedia('(prefers-reduced-motion)');
  const { number } = useSpring({
    from: { number: 0 },
    to: { number: upTo },
    immediate: prefersReducedMotion,
    pause: !enabled,
    delay: DELAY,
    config: {
      duration: 3000,
      easing: (t) =>
        t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
    },
  });

  return number.to((n) => n.toFixed(0));
}

type CountUpNumberProps = {
  children: Interpolation<number, string>;
};

export function CountUpNumber({ children }: CountUpNumberProps) {
  return <animated.span>{children}</animated.span>;
}
