var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { AnalyticsProvider } from './AnalyticsContext';
import useMixpanel from './useMixpanel';
export default function MixpanelProvider(_a) {
    var children = _a.children, enable = _a.enable, fallback = _a.fallback, token = _a.token;
    var mixpanel = useMixpanel({ token: token, enable: enable });
    if (mixpanel.loading || mixpanel.error) {
        return fallback;
    }
    return (_jsx(AnalyticsProvider, __assign({ value: mixpanel.value }, { children: children }), void 0));
}
