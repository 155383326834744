import debounce from 'lodash.debounce';
import { useEffect, useMemo } from 'react';
export default function useDebounceCallback(callback, delay) {
    if (delay === void 0) { delay = 300; }
    var debouncedChangeHandler = useMemo(function () { return debounce(callback, delay); }, [callback, delay]);
    useEffect(function () {
        return function () {
            debouncedChangeHandler.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return debouncedChangeHandler;
}
