import { useCallback, useMemo } from 'react';
import { useMutation } from 'react-query';

import {
  postAuthentication,
  PostAuthenticationBody,
  PostAuthenticationReponse,
} from './api';

import * as mapper from './mapper';

export default function useLogin() {
  const {
    mutateAsync,
    data: response,
    error,
    isLoading,
  } = useMutation<PostAuthenticationReponse, Error, PostAuthenticationBody>(
    postAuthentication,
  );

  const login = useCallback(
    async (params?: any) => {
      await mutateAsync({
        user: params.user,
        password: params.password,
      });
    },
    [mutateAsync],
  );

  const data = useMemo(() => {
    return {
      token: response?.token,
      refreshToken: response?.refresh_token,
      identity: response
        ? mapper.postAuthenticationReponseToIdentity(response)
        : undefined,
    };
  }, [response]);

  return [
    login,
    {
      data,
      error,
      isLoading,
    },
  ] as const;
}
