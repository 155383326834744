export default function shouldFetch(args) {
    if (args == null)
        return false;
    if (Array.isArray(args)) {
        return validateArrayValues(args);
    }
    if (typeof args === 'object') {
        var values = Object.values(args);
        return validateArrayValues(values);
    }
    return !!String(args);
}
function validateArrayValues(array) {
    if (array.length === 0)
        return false;
    var hasInvalidValue = array.some(function (val) {
        if (val == null || !String(val))
            return true;
        return false;
    });
    return !hasInvalidValue;
}
