import { useCallback } from 'react';
export function useDownloadBlob(_a) {
    var ext = _a.ext;
    return useCallback(function (fileName, blob) {
        var element = document.createElement('a');
        var url = window.URL.createObjectURL(blob);
        element.setAttribute('href', url);
        element.setAttribute('download', fileName + "." + ext);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }, [ext]);
}
export function downloadBlob(_a) {
    var ext = _a.ext, blob = _a.blob, fileName = _a.fileName;
    var element = document.createElement('a');
    var url = window.URL.createObjectURL(blob);
    element.setAttribute('href', url);
    element.setAttribute('download', fileName + "." + ext);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}
export function useDownloadBase64(_a) {
    var type = _a.type, ext = _a.ext;
    return function download(fileName, base64) {
        var element = document.createElement('a');
        element.setAttribute('href', type + "," + base64);
        element.setAttribute('download', fileName + "." + ext);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };
}
export function downloadBase64(_a) {
    var type = _a.type, ext = _a.ext, base64 = _a.base64, fileName = _a.fileName;
    var element = document.createElement('a');
    element.setAttribute('href', "data:" + type + ";base64," + base64);
    element.setAttribute('download', fileName + "." + ext);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}
