import times from 'lodash/times';
import { rest } from '@recordkeeping/common/build/msw/server';
import { createLoanRules } from './factories';

export default [
  rest.get(
    'https://api.recordkeeping.dev-gbm.com/v2/contracts/:contractId/rules',
    (req, res, ctx) => {
      const { contractId } = req.params;
      const loanRules = times(4, (index) =>
        createLoanRules({ contractId, index }),
      );

      return res(ctx.json(loanRules));
    },
  ),
];
