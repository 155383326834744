import useMediaQuery from './useMediaQuery';
export default function useResponsiveProp(unit) {
    var mediumScreen = useMediaQuery('md');
    var largeScreen = useMediaQuery('lg');
    var xlargeScreen = useMediaQuery('xl');
    var xxlargeScreen = useMediaQuery('2xl');
    if (!Array.isArray(unit)) {
        return unit;
    }
    var normalized = unit.reduce(function (prev, current, index) {
        return prev.fill(current, index);
    }, Array.from({ length: 5 }));
    if (xxlargeScreen) {
        return normalized[normalized.length - 1];
    }
    if (xlargeScreen) {
        return normalized[normalized.length - 2];
    }
    if (largeScreen) {
        return normalized[normalized.length - 3];
    }
    if (mediumScreen) {
        return normalized[normalized.length - 4];
    }
    return normalized[normalized.length - 5];
}
