import React from 'react';
import { useTrackEvent } from '@recordkeeping/common/build/analytics';
import { useTranslation } from '@recordkeeping/common/build/internationalization/InternationalizationContext';

import {
  ContentBlock,
  Stack,
  Padding,
} from '@recordkeeping/common/build/layout';

export default function ContactBlock() {
  const t = useTranslation();
  const trackEvent = useTrackEvent('Contacto');

  function handleContactClick(kind: string) {
    return () => trackEvent({ kind: t(kind) });
  }

  return (
    <span className="w-full  text-white">
      <ContentBlock width={['xl', 'xl', 'xl', 300]}>
        <Stack space="md">
          <Padding padding="none" paddingBottom="lg">
            <p className="text-h5">Contacto</p>
          </Padding>
          <p>Ricardo E. González de la Colina</p>
          <p>
            <a
              onClick={handleContactClick('phone')}
              href="tel:+55 5480-5800;ext=4727"
              className="link filter invert"
            >
              Tel. 55 5480-5800 ext. 4727
            </a>
          </p>
          <div>
            <a
              onClick={handleContactClick('email')}
              href="mailto:regonzalez@gbm.com"
              className="link filter invert"
            >
              regonzalez@gbm.com
            </a>
          </div>
        </Stack>
      </ContentBlock>
    </span>
  );
}
