import { useMemo } from 'react';
import { useCreateFormInstance } from '@recordkeeping/common/build/form';
import * as Yup from 'yup';

type OnSubmit = (values: LoginFormValues) => Promise<void>;
export type LoginFormValues = {
  user?: string;
  password?: string;
};

export default function useLoginForm(onSubmit: OnSubmit) {
  const validationSchema = useMemo(() => {
    return Yup.object({
      user: Yup.string().required('user is required'),
      password: Yup.string().required('password is required'),
    });
  }, []);

  return useCreateFormInstance<LoginFormValues>({
    validationSchema,
    initialValues: {
      user: undefined,
      password: undefined,
    },
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values).finally(() => setSubmitting(false));
    },
  });
}
