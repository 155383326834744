var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Cover as CoverBase } from '@bedrock-layout/cover';
import useResponsiveProp from '../useResponsiveProp';
export default function Cover(_a) {
    var children = _a.children, height = _a.height;
    var heightProp = useResponsiveProp(height);
    return (_jsx(CoverBase, __assign({ gutter: "none", minHeight: heightProp }, { children: children }), void 0));
}
