import React from 'react';
import { useTranslation } from '@recordkeeping/common/build/internationalization';
import { upperFirst } from '@recordkeeping/common/build/string';

import { Contract } from 'contracts';
import BalanceHeader from 'components/BalanceHeader';
import { useContractsValue } from 'contracts/ContractsProvider/ContractsProvider';

export default function ResumeTotalAmount() {
  const t = useTranslation();
  const { data, isLoading } = useContractsValue();
  const totalAmount = getTotalAmount(data);

  return (
    <BalanceHeader
      loading={isLoading}
      label={upperFirst(t('total value of your contracts'))}
      amount={totalAmount}
    />
  );
}

function getTotalAmount(contracts: ReadonlyArray<Contract> | undefined) {
  return contracts == null
    ? 0
    : contracts?.reduce((acc, contract) => acc + contract.balance, 0);
}
